<template>
  <label
    :for="elementId"
    class="v-radio"
    :class="classNames($style.Radio, mods)"
  >
    <input
      :id="elementId"
      type="radio"
      :class="$style.native"
      :value="optionValue"
      :checked="optionChecked"
    />
    <span :class="$style.body">
      <span :class="$style.arrow" />
    </span>
    {{ optionLabel }}
  </label>
</template>

<script lang="ts" setup>
export interface Props {
  optionValue?: number | string;
  optionChecked?: boolean;
  optionLabel?: string | null;
}

const props = withDefaults(defineProps<Props>(), {
  optionValue: 0,
  optionChecked: true,
  optionLabel: null,
});

const elementId = `v-radio-${Math.random().toString(36).slice(2, 7)}`;

const style = useCssModule();
const mods = computed(() => ({
  [style.checked]: props.optionChecked,
}));
</script>

<style lang="scss" module>
.Radio {
  display: flex;
  align-items: center;
  outline: none;
  user-select: none;
  cursor: pointer;
  color: $color-text-checkbox;
  font-weight: 400;
  font-size: 1.5rem;
  line-height: normal;
}

.native {
  opacity: 0;
}

.body {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 2.4rem;
  height: 2.4rem;
  margin-right: 0.8rem;
  border: 1px solid $body-color;
  background-color: transparent;
  transition:
    background-color $default-transition,
    border-color $default-transition;
}

.arrow {
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  width: 1.2rem;
  height: 1.2rem;
  background-color: transparent;
  transform: scale(0);
}

.checked {
  & .arrow {
    background-color: #c0ed0b;
    transform: scale(1) translate(-50%, -50%);
  }
}
</style>
